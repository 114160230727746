@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Open+Sans:wght@300;400&display=swap');
@import "~bootstrap/scss/bootstrap";
@import "./mixins";
body {
  font-family: 'Open Sans', sans-serif;
  font-size: .9rem;
  font-weight: 300;
  color: #737b9a;
}

h1,
h2,
h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #233d63;
}

h5 {
  font-family: 'Montserrat', sans-serif;
}

.text-justify {
  text-align: justify;
}

.section-content {
  padding-top: 5rem;
  // padding-bottom: 4rem;
}

.alternative {
  background: #fff5f4;
}

#ksum-footer {
  background: #222229;
  color: #dbdbdb;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include my_bp(sm) {
    flex-direction: row;
  }
  .left {
    text-align: center;
    @include my_bp(sm) {
      width: 35%;
    }
    p {
      margin: 0;
    }
    .phone {
      font-size: 1.5rem;
      font-weight: 600;
      color: white;
      margin: 1rem 0;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .5rem;
        color: #3fafef;
      }
    }
  }
  .right {
    @include my_bp(sm) {
      width: 35%;
    }
  }
}

#ksum-copy {
  background: #1c1c21;
  color: #b1b1b1;
  padding: 20px 0;
  text-align: center;
}

.sclogo {
  width: 40%;
}